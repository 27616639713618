import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import {
  Typography,
  Container,
  Box,
  Card,
  Grid,
  CardContent,
  CardActions,
  Button,
  List,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/StarBorder';
import { PricingPageQuery } from '../../types/graphql-types';
import RichContent from '../components/text/rich-content';
import CardHeader from '../components/Card/CardHeader';
import CardPricing from '../components/Card/CardPricing';

export interface ProductPageProps {
  data: PricingPageQuery;
}

interface Tier {
  title: string;
  subheader?: string;
  price: string;
  unit?: string;
  description: string[];
  buttonText: string;
  buttonVariant: 'outlined' | 'contained' | 'text';
  disabled?: boolean;
}

const tiers: Tier[] = [
  {
    title: '30 days trial',
    price: '0',
    description: ['Full experince', '30 GB of storage'],
    buttonText: 'Sign up now',
    buttonVariant: 'outlined',
  },
  {
    title: 'Floating licenses',
    subheader: 'Most popular',
    price: '450',
    unit: '/year',
    description: ['Updates and upgrades are free of charge', 'Used on several different computers'],
    buttonText: 'Sign up & Contact us',
    buttonVariant: 'contained',
  },
  {
    title: 'Node locked',
    price: '1000',
    description: ['Bound to a single computer', 'No time-limitation'],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
    disabled: true,
  },
];

const PricingPage = ({ data }: ProductPageProps): JSX.Element => {
  const { contentfulPricing } = data;

  return (
    <>
      <SEO title={contentfulPricing?.title || ''} />
      <Box mb={5} mt={5}>
        <Container>
          <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
            {contentfulPricing?.title}
          </Typography>
          <Typography variant="h5" color="textSecondary" paragraph>
            {contentfulPricing?.description?.description}
          </Typography>
          <Box mb={5} mt={5}>
            <Grid container spacing={5} alignItems="flex-end">
              {tiers.map((tier) => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={tier.title === 'Enterprise' ? 12 : 6}
                  md={4}
                >
                  <Card>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      action={tier.title === 'Pro' ? <StarIcon /> : null}
                    />
                    <CardContent>
                      <CardPricing>
                        <Typography component="h2" variant="h3" color="textPrimary">
                          €{tier.price}
                        </Typography>
                        {tier.unit && (
                          <Typography variant="h6" color="textSecondary">
                            {tier.unit}
                          </Typography>
                        )}
                      </CardPricing>
                      <List>
                        {tier.description.map((line) => (
                          <Typography component="li" variant="subtitle1" align="center" key={line}>
                            {line}
                          </Typography>
                        ))}
                      </List>
                    </CardContent>
                    <CardActions>
                      <Button
                        disabled={tier.disabled}
                        fullWidth
                        variant={tier.buttonVariant}
                        color="primary"
                      >
                        {tier.buttonText}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <RichContent
            dangerouslySetInnerHTML={{
              __html: contentfulPricing?.body?.childMarkdownRemark?.html || '',
            }}
          />
        </Container>
      </Box>
    </>
  );
};

export default PricingPage;

export const pageQuery = graphql`
  query PricingPage {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPricing {
      title
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
