import styled from 'styled-components';

const CardPricing = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
  justify-content: center;
`;

export default CardPricing;
